import styles from "./Users.module.css";
import { IoSearch } from "react-icons/io5";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Loader from "../components/ui/elements/custom/Loader";
import StatusBadge from "../components/ui/elements/custom/StatusBadge";
import { CgMoreO } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { supabase } from "../supabase/client";
import { useUser } from "../auth/userContext";

const ITEMS_PER_PAGE = 20; // Set to a more realistic value

function Users() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const navigate = useNavigate();
  const { authSession } = useUser();

  const fetchData = async (page = 1, keyword = "") => {
    setIsLoading(true);

    const from = (page - 1) * ITEMS_PER_PAGE;
    const to = from + ITEMS_PER_PAGE - 1;

    try {
      const {
        data: users,
        count,
        error: usersError,
      } = await supabase
        .from("users")
        .select(
          "id, first_name, last_name, full_name, email, active,  last_sign_in, auth_user_id, role_id(role), user_hotel (hotels ( code))",
          { count: "exact" }
        )
        .range(from, to)
        .order("full_name")
        .ilike("full_name", `%${keyword}%`);

      if (usersError) {
        throw usersError;
      }

      setTotalRecords(count);
      setPageCount(Math.ceil(count / ITEMS_PER_PAGE));

      setData(users);
    } catch (error) {}

    setIsLoading(false);
  };

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || "1");
    const keyword = searchParams.get("keyword") || "";
    setSearchKeyword(keyword);
    fetchData(page, keyword);
  }, [searchParams]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKeyword) {
      searchParams.set("keyword", searchKeyword);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
      setSearchMode(true);
    }
  };

  const cancelSearch = (e) => {
    e.preventDefault();
    if (searchParams.has("keyword")) {
      searchParams.delete("keyword");
    }
    if (searchParams.has("page")) {
      searchParams.delete("page");
    }
    setSearchParams(searchParams);
    setSearchMode(false);
  };

  const currentPage = parseInt(searchParams.get("page") || "1");
  const fromRecord = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const toRecord = Math.min(currentPage * ITEMS_PER_PAGE, totalRecords);

  return (
    <>
      <div className={styles.main_container}>
        <h4 className={styles.page_title}>Users</h4>

        <div className={styles.searchAndAdd}>
          <div>
            <form className={styles.searchForm}>
              <input
                type="search"
                placeholder="John Doe"
                className={styles.searchBox}
                onChange={(e) => setSearchKeyword(e.target.value)}
                value={searchKeyword}
              />
              <button onClick={handleSearch} title="Search users">
                <IoSearch />
                Search
              </button>
              <button
                onClick={cancelSearch}
                title="Cancel search"
                className={`${styles.neutral_btn} ${
                  searchMode ? styles.unhide : styles.hide
                }`}
              >
                <RxCross2 />
                Clear
              </button>
            </form>
          </div>

          <button onClick={() => navigate("new")}>New user</button>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles.table_container}>
            {totalRecords > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Hotels</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((user) => (
                      <tr key={user.id}>
                        <td className={styles.capitalize}>{user.full_name}</td>
                        <td className={styles.lowercase}>{user.email}</td>
                        <td className={styles.uppercase}>
                          {user.user_hotel
                            .map((uh) => uh.hotels?.code)
                            .filter((code) => code) // Filter out any falsy values
                            .join(", ")}
                        </td>
                        <td className={styles.capitalize}>
                          {user.role_id.role}
                        </td>
                        <td>
                          <StatusBadge
                            status={
                              user.active && user.last_sign_in
                                ? "active"
                                : user.active && !user.last_sign_in
                                ? "pending"
                                : "blocked"
                            }
                          />
                        </td>
                        <td className={styles.action_column}>
                          {user.auth_user_id !== authSession?.user?.id ? (
                            <>
                              <button
                                className={styles.action_btns}
                                title="Details"
                                onClick={() => navigate(`${user.id}`)}
                              >
                                <CgMoreO /> Details
                              </button>
                              <button
                                className={styles.action_btns}
                                title="Edit"
                                onClick={() => navigate(`${user.id}/edit`)}
                              >
                                <FiEdit /> Edit
                              </button>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className={styles.summary_and_pagination}>
                  <p className={styles.pagination_summary}>
                    Displaying {fromRecord} to {toRecord} of {totalRecords}{" "}
                    users
                  </p>

                  <ReactPaginate
                    nextLabel="Next >"
                    previousLabel="< Previous"
                    breakLabel="..."
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    renderOnZeroPageCount={null}
                    containerClassName={styles.pagination_container}
                    pageClassName={styles.page_item}
                    nextClassName={styles.nxt_prev}
                    previousClassName={styles.nxt_prev}
                    activeClassName={styles.active_page}
                    forcePage={currentPage - 1} // Ensure the active page is correctly set
                  />
                </div>
              </>
            ) : (
              <div className={styles.centered}>
                <h4 className={styles.margin_below}>No records!</h4>
                <p>
                  There are no users matching your search. Please enter another
                  search query.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Users;
