import styles from "./Hotels.module.css";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StatusBadge from "../components/ui/elements/custom/StatusBadge";
import Loader from "../components/ui/elements/custom/Loader";
import { supabase } from "../supabase/client";
import { RxCross2 } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { CgMoreO } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";

const ITEMS_PER_PAGE = 20; // Set to a more realistic value

function Hotels() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const navigate = useNavigate();

  const fetchPageData = async (page = 1, keyword = "") => {
    setIsLoading(true);
    const from = (page - 1) * ITEMS_PER_PAGE;
    const to = from + ITEMS_PER_PAGE - 1;

    let query = supabase
      .from("hotels")
      .select(
        "id, created_at, name, api_identifier, created_by, code, active, api_provider_id(name)",
        { count: "exact" }
      )
      .range(from, to)
      .order("name");

    if (keyword) {
      query = query.or(`name.ilike.%${keyword}%,code.ilike.%${keyword}%`);
    }

    const { data, count, error } = await query;

    if (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false);
      return;
    }

    if (data) {
      setData(data);
      setTotalRecords(count);
      setPageCount(Math.ceil(count / ITEMS_PER_PAGE));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || "1");
    const keyword = searchParams.get("keyword") || "";
    setSearchKeyword(keyword);
    fetchPageData(page, keyword);
  }, [searchParams]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKeyword) {
      searchParams.set("keyword", searchKeyword);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
      setSearchMode(true);
    }
  };

  const cancelSearch = (e) => {
    e.preventDefault();
    if (searchParams.has("keyword")) {
      searchParams.delete("keyword");
    }
    if (searchParams.has("page")) {
      searchParams.delete("page");
    }
    setSearchParams(searchParams);
    setSearchMode(false);
  };

  const currentPage = parseInt(searchParams.get("page") || "1");
  const fromRecord = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const toRecord = Math.min(currentPage * ITEMS_PER_PAGE, totalRecords);

  return (
    <>
      <div className={styles.main_container}>
        <h4 className={styles.page_title}>Hotels</h4>

        <div className={styles.searchAndAdd}>
          <div>
            <form className={styles.searchForm}>
              <input
                type="search"
                placeholder="Hotel name or code"
                className={styles.searchBox}
                onChange={(e) => setSearchKeyword(e.target.value)}
                value={searchKeyword}
              />
              <button onClick={handleSearch} title="Search hotels">
                <IoSearch />
                Search
              </button>
              <button
                onClick={cancelSearch}
                title="Cancel search"
                className={`${styles.neutral_btn} ${
                  searchMode ? styles.unhide : styles.hide
                }`}
              >
                <RxCross2 />
                Clear
              </button>
            </form>
          </div>

          <button onClick={() => navigate("new")}>New hotel</button>
        </div>
        {isLoading ? (
          <Loader  />
        ) : (
          <div className={styles.table_container}>
            {totalRecords > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Provider</th>
                      <th>Resort Code</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((hotel) => (
                      <tr key={hotel.id}>
                        <td className={styles.capitalize}>{hotel.name}</td>
                        <td className={styles.all_caps}>{hotel.code}</td>
                        <td>{hotel.api_provider_id.name}</td>
                        <td>{hotel.api_identifier}</td>
                        <td>
                          <StatusBadge
                            status={hotel.active ? "active" : "blocked"}
                          />
                        </td>
                        <td className={styles.action_column}>
                          <button
                            className={styles.action_btns}
                            title="Details"
                            onClick={() => navigate(`${hotel.id}`)}
                          >
                            <CgMoreO /> Details
                          </button>
                          <button
                            className={styles.action_btns}
                            title="Edit"
                            onClick={() => navigate(`${hotel.id}/edit`)}
                          >
                            <FiEdit /> Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className={styles.summary_and_pagination}>
                  <p className={styles.pagination_summary}>
                    Displaying {fromRecord} to {toRecord} of {totalRecords}{" "}
                    hotels
                  </p>

                  <ReactPaginate
                    nextLabel="Next >"
                    previousLabel="< Previous"
                    breakLabel="..."
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    renderOnZeroPageCount={null}
                    containerClassName={styles.pagination_container}
                    pageClassName={styles.page_item}
                    nextClassName={styles.nxt_prev}
                    previousClassName={styles.nxt_prev}
                    activeClassName={styles.active_page}
                    forcePage={currentPage - 1} // Ensure the active page is correctly set
                  />
                </div>
              </>
            ) : (
              <div className={styles.centered}>
                <h4 className={styles.margin_below}>No records!</h4>
                <p>
                  There are no hotels matching your search. Please enter another
                  search query.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Hotels;
