import { format } from "date-fns";
import {
  Body,
  Button,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Section,
  Text,
} from "@react-email/components";

export function Email({ token, email, firstName }) {
  // const { token, email, firstName } = props;

  const url =
    import.meta.env.MODE === "production"
      ? import.meta.env.VITE_PRD_FRONTEND_URL
      : import.meta.env.VITE_DEV_FRONTEND_URL;

  return (
    <Html lang="en">
      <Body style={main}>
        <Container style={container}>
          <Img
            src="https://uxzumbtxavruynxjkuxb.supabase.co/storage/v1/object/public/bens_public_assets/logo_red.png"
            width="70"
            height="auto"
            alt="BENS"
            style={logo}
          />
          <Text style={paragraph}>Hi {firstName},</Text>
          <Text style={paragraph}>
            We received a request to reset the password for your account
            associated with this email address. If you made this request, please
            follow the instructions below to reset your password. If you did not
            request a password reset, please ignore this email, and your
            password will remain unchanged.
          </Text>
          <Text style={paragraph}>
            <strong>To reset your password:</strong>
          </Text>
          <Text style={paragraph}>
            <ol style={paragraph}>
              <li> Click on the button below.</li>
              <li>
                Follow the instructions on the password reset page to create a
                new password.
              </li>
            </ol>
          </Text>
          <Section style={btnContainer}>
            <Button
              style={button}
              href={`${url}/password?email=${email}&token=${token}&event=newPassword`}
            >
              Reset Your Password
            </Button>
          </Section>
          <Text style={paragraph}>
            If you have any questions or need further assistance, please do not
            hesitate to reach out to our support team.
          </Text>
          <Text style={paragraph}>
            Best regards,
            <br />
            The BENS team
          </Text>
          <Hr style={hr} />
          <Text style={footer}>&copy; {format(new Date(), "yyyy")} BENS</Text>
        </Container>
      </Body>
    </Html>
  );
}

export default Email;

const main = {
  backgroundColor: "#ffffff",
  fontFamily: "sans-serif",
};

const container = {
  margin: "0 auto",
  padding: "20px 0 48px",
};

const logo = {
  margin: "0 auto 32px auto",
};

const paragraph = {
  fontSize: "16px",
  lineHeight: "26px",
};

const btnContainer = {
  textAlign: "center",
};

const button = {
  backgroundColor: "#d70e2b;",
  borderRadius: "4px",
  color: "#fff",
  fontSize: "16px",
  textDecoration: "none",
  textAlign: "center",
  display: "block",
  padding: "12px",
};

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
};
