// Imports
import Logo from "../../ui/elements/custom/Logo";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { MdHotel } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import { MdInsights } from "react-icons/md";
import { useUser } from "../../../auth/userContext";
import UserDropdown from "../../ui/elements/custom/UserDropDown";
import { useEffect, useState } from "react";

function Header() {
  const { userProfile } = useUser();

  const [userRole, setUserRole] = useState("");

  const showToRoles = (roles) => {
    // Show or hide menu items based on user role
    return roles.includes(userRole);
  };

  useEffect(() => {
    // Set user role
    const getUserRole = async () => {
      if (userProfile) setUserRole(userProfile.role_id.role);
    };

    getUserRole();
  }, [userProfile]);

  return (
    <>
      <header>
        <a href="/">
          <Logo src="/images/logo-red.png" alt="BENS logo" />
        </a>

        <nav>
          <menu>
            <NavLink
              to="/"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              title="Dashboard"
            >
              <RiDashboardFill /> Dashboard
            </NavLink>
            <NavLink
              to="/competitive-insights"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              title="Competitive Insights"
            >
              <MdInsights />
              Competitive Insights
            </NavLink>

            <NavLink
              to="/users"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              hidden={
                !showToRoles([
                  "admin",
                  "internal admin",
                  "superadmin",
                  "developer",
                ])
              }
              title="User Management"
            >
              <HiUsers /> User Management
            </NavLink>

            <NavLink
              to="/hotels"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              hidden={!showToRoles(["superadmin", "developer"])}
              title="Hotel Management"
            >
              <MdHotel /> Hotel Management
            </NavLink>

            {/* <NavLink
              to="/hotel-settings"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              hidden={
                !showToRoles([
                  "admin",
                  "internal admin",
                  "superadmin",
                  "developer",
                ])
              }
              title="Hotel Setup"
            >
              <LuSettings2 /> Hotel Setup
            </NavLink> */}

            <NavLink
              to="/app-settings"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              hidden={!showToRoles(["superadmin", "developer"])}
              title="Application settings"
            >
              <LuSettings2 /> Application Settings
            </NavLink>
          </menu>
        </nav>
        <nav>
          <div className={styles.user}>
            <UserDropdown user={userProfile} />
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
