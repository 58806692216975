import { format } from "date-fns";
import {
  Body,
  Button,
  Container,
  Hr,
  Html,
  Img,
  Section,
  Text,
} from "@react-email/components";

export function Email({ firstName = "", updatedFields = [] }) {
  const url =
    import.meta.env.MODE === "production"
      ? import.meta.env.VITE_PRD_FRONTEND_URL
      : import.meta.env.VITE_DEV_FRONTEND_URL;

  return (
    <Html lang="en">
      <Body style={main}>
        <Container style={container}>
          <Img
            src="https://uxzumbtxavruynxjkuxb.supabase.co/storage/v1/object/public/bens_public_assets/logo_red.png"
            width="70"
            height="auto"
            alt="BENS"
            style={logo}
          />
          <Text style={paragraph}>Hi {firstName},</Text>
          <Text style={paragraph}>
            Your profile has been updated with the following changes:
          </Text>
          <Text style={paragraph}>
            <ul style={paragraph}>
              {updatedFields.length > 0 ? (
                updatedFields.map((field, index) => (
                  <li key={index} dangerouslySetInnerHTML={{ __html: field }} />
                ))
              ) : (
                <li>No changes detected.</li>
              )}
            </ul>
          </Text>

          <Text style={paragraph}>
            To review your updated profile, please log in to your account.
          </Text>
          <Section style={btnContainer}>
            <Button style={button} href={`${url}/profile`}>
              Log in to your account
            </Button>
          </Section>
          <Text style={paragraph}>
            If you have any questions or need assistance, our team is here to
            help.
          </Text>
          <Text style={paragraph}>The BENS team</Text>
          <Hr style={hr} />
          <Text style={footer}>&copy; {format(new Date(), "yyyy")} BENS</Text>
        </Container>
      </Body>
    </Html>
  );
}

export default Email;

const main = {
  backgroundColor: "#ffffff",
  fontFamily: "sans-serif",
};

const container = {
  margin: "0 auto",
  padding: "20px 0 48px",
  fontFamily: "sans-serif",
};

const logo = {
  margin: "0 auto 32px auto",
};

const paragraph = {
  fontSize: "16px",
  lineHeight: "26px",
};

const btnContainer = {
  textAlign: "center",
};

const button = {
  backgroundColor: "#d70e2b;",
  borderRadius: "4px",
  color: "#fff",
  fontSize: "16px",
  textDecoration: "none",
  textAlign: "center",
  display: "block",
  padding: "12px",
};

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
  fontFamily: "sans-serif",
};
