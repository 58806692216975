import { useNavigate, useParams } from "react-router-dom";
import styles from "./Hotel.module.css";
import { supabase } from "../supabase/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/ui/elements/custom/Loader";
import { format } from "date-fns";
import { IoChevronForwardSharp } from "react-icons/io5";
import formatDateForUser from "../utils/formatDateForUser";

function Hotel() {
  const [hotel, setHotel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();

  const fetchHotel = async (id) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("hotels")
        .select(
          "name, code, api_provider_id(name), api_identifier, active, created_at"
        )
        .eq("id", id)
        .single();

      if (error) {
        toast.error(
          () => (
            <>
              <p>Failed to retrieve hotel information!</p>
              <p className={styles.info}>Reason: {error.message}</p>
            </>
          ),
          {
            toastId: "errortoast",
          }
        );
        navigate("/hotels");
        return;
      }

      setHotel(data);
    } catch (error) {
      toast.error(
        () => (
          <>
            <p>Failed to retrieve hotel information!</p>
            <p className={styles.info}>Reason: {error.message}</p>
          </>
        ),
        {
          toastId: "errortoast",
        }
      );
      navigate("/hotels");
    }
    setIsLoading(false);
  };

  const deleteHotel = async (id) => {
    toast.promise(
      supabase
        .from("hotels")
        .delete()
        .eq("id", id)
        .single()
        .then(({ data, error }) => {
          if (error) throw error;
          return data;
        }),
      {
        pending: `Deleting ${hotel.name}...`,
        success: {
          render() {
            navigate("/hotels");
            return `${hotel.name} successfully deleted!`;
          },
        },
        error: {
          render({ data }) {
            return (
              <>
                <p>Deleting {hotel.name} failed!</p>
                <p className={styles.info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      }
    );
  };

  useEffect(() => {
    if (params.id) {
      fetchHotel(params.id);
    }
  }, [params.id, navigate]);

  return (
    <>
      <div className={styles.main_container}>
        {isLoading ? (
          <Loader  />
        ) : (
          <>
            <h4 className={styles.page_title}>
              Hotels <IoChevronForwardSharp /> {hotel.name}
            </h4>

            <div className={styles.form_container}>
              <h3 className={styles.bold}>Details</h3>
              <ul className={styles.user_details}>
                <li className={styles.detail}>
                  <span className={styles.bold}>Created:</span>{" "}
                  <span>{formatDateForUser(hotel.created_at)}</span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Name:</span>{" "}
                  <span className={styles.capitalize}>{hotel.name}</span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Code:</span>{" "}
                  <span className={styles.all_caps}>{hotel.code}</span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Provider:</span>{" "}
                  {hotel.api_provider_id.name}
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Resort Code:</span>{" "}
                  {hotel.api_identifier}
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Status:</span>{" "}
                  <span className={styles.capitalize}>
                    {hotel.active ? "active" : "blocked"}
                  </span>
                </li>
              </ul>
              <div className={styles.fn_btns}>
                <button
                  onClick={() => navigate("/hotels")}
                  title="Return to client list"
                  className="neutral_btn"
                >
                  Return to hotel list
                </button>
                <button onClick={() => navigate("edit")}>
                  Edit this hotel
                </button>
                <button
                  onClick={() => {
                    if (
                      confirm(`Do you really want to delete ${hotel.name}?`)
                    ) {
                      deleteHotel(params.id);
                    }
                  }}
                >
                  Delete this hotel
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Hotel;
