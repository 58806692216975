import { Navigate } from "react-router-dom";
import Header from "../../components/sections/main/Header";
import { useUser } from "../../auth/userContext";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { authSession, userProfile, loading } = useUser();

  if (loading) {
    // Render loading state or null while authentication state is initializing
    return null; // You can also render a loading spinner or message
  }

  if (!authSession) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(userProfile?.role_id.role)) {
    // Redirect to home page if user's role is not included in list of allowed roles
    return <Navigate to="/not-found" />;
  }

  // If authenticated and authentication state is ready, render the children components
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default ProtectedRoute;
