import { Navigate } from "react-router-dom";
import { useUser } from "../../auth/userContext";

const OpenRoute = ({ children }) => {
  const { authSession, loading } = useUser();

  if (loading) {
    // Render loading state or null while authentication state is initializing
    return null; // You can also render a loading spinner or message
  }

  if (authSession) {
    // Redirect to login page if not authenticated
    return <Navigate to="/" />;
  }

  // If authenticated and authentication state is ready, render the children components
  return children;
};

export default OpenRoute;
