import styles from "./Loader.module.css";
import PropType from "prop-types";

function Loader({ message, size }) {
  return (
    <>
      <div className={styles.container}>
        <p>{message}</p>
        <p>
          <span className={styles.loader}></span>
        </p>
      </div>
    </>
  );
}

Loader.propTypes = {
  message: PropType.string,
  size: PropType.string.isRequired,
};

export default Loader;
