import styles from "./StatusBadge.module.css";
import PropType from "prop-types";

function StatusBadge({ status }) {
  const addClass = function () {
    if (status.toLowerCase() === "active") return styles.green;
    else if (status.toLowerCase() === "pending") return styles.amber;
    else if (status.toLowerCase() === "blocked") return styles.red;
    else return null;
  };

  return <span className={`${styles.badge} ${addClass()}`}>{status}</span>;
}

StatusBadge.propTypes = {
  status: PropType.string.isRequired,
};

export default StatusBadge;
