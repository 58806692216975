import { format } from "date-fns";
import { Body, Container, Hr, Html, Img, Text } from "@react-email/components";

export function Email({ firstName }) {
  return (
    <Html lang="en">
      <Body style={main}>
        <Container style={container}>
          <Img
            src="https://uxzumbtxavruynxjkuxb.supabase.co/storage/v1/object/public/bens_public_assets/logo_red.png"
            width="70"
            height="auto"
            alt="BENS"
            style={logo}
          />
          <Text style={paragraph}>Hi {firstName},</Text>
          <Text style={paragraph}>
            Welcome to BENS! We're thrilled to have you on board. 🎉
          </Text>

          <Text style={paragraph}>
            At BENS, we're dedicated to providing you with powerful tools and
            insights to help you make informed decisions and drive success in
            your business.
          </Text>
          <Text style={paragraph}>
            Here are a few things you can do to get started:
          </Text>
          <Text style={paragraph}>
            <ol style={paragraph}>
              <li style={listItem}>
                <strong>Explore Your Dashboard:</strong> Log in to your account
                and take a tour of your personalized dashboard. It's designed to
                give you a quick overview of your key metrics and performance.
              </li>
              <li style={listItem}>
                <strong>Set Up Your Preferences:</strong> Customize your
                settings to tailor your experience to your needs. Whether it's
                adjusting notification preferences or configuring data sources,
                we've got you covered.
              </li>
              <li style={listItem}>
                <strong>Discover Insights:</strong> Dive deeper into your data
                with our advanced analytics tools. From trend analysis to
                predictive modeling, unlock actionable insights that can drive
                growth and innovation.
              </li>
              <li style={listItem}>
                <strong>Get Support:</strong> Have questions or need assistance?
                Our support team is here to help. Reach out to us anytime, and
                we'll be happy to assist you.
              </li>
            </ol>
          </Text>

          <Text style={paragraph}>
            We're excited to embark on this journey with you and support you
            every step of the way. If you have any feedback or suggestions, we'd
            love to hear from you.
          </Text>
          <Text style={paragraph}>
            Once again, welcome to BENS Analytics! Let's make great things
            happen together.
          </Text>
          <Text style={paragraph}>
            Best regards,
            <br />
            The BENS team
          </Text>
          <Hr style={hr} />
          <Text style={footer}>&copy; {format(new Date(), "yyyy")} BENS</Text>
        </Container>
      </Body>
    </Html>
  );
}

export default Email;

const main = {
  backgroundColor: "#ffffff",
  fontFamily: "sans-serif",
};

const container = {
  margin: "0 auto",
  padding: "20px 0 48px",
};

const logo = {
  margin: "0 auto 32px auto",
};

const paragraph = {
  fontSize: "16px",
  lineHeight: "26px",
};

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
};

const listItem = { marginBottom: "20px" };
