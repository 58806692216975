import { formatInTimeZone } from "date-fns-tz";

function formatDateForUser(date) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateFormat = "MMMM do, yyyy hh:mm a";

  return `${formatInTimeZone(
    date,
    userTimezone,
    dateFormat
  )} (${userTimezone.replace(/_/g, " ")})`; // replace "_" in the timezone name with a space
}

export default formatDateForUser;
