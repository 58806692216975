import { supabase } from "../supabase/client.js";
import getUserRole from "./getRole.js";

const isPermitted = async (newUserRoleId, session) => {
  // get the role of the current user
  try {
    const { data, error } = await supabase
      .from("users")
      .select("role_id(role)")
      .eq("auth_user_id", session.user.id)
      .single();

    if (error) {
      throw new Error("Error fetching user role");
    }

    return permitted(data.role_id.role, await getUserRole(newUserRoleId));
  } catch (error) {
    console.error("Error fetching role:", error);
    return false;
  }
};

const permitted = (creatorRole, newUserRole) => {
  if (creatorRole === "viewer") {
    return false;
  }
  if (
    creatorRole === "admin" &&
    (newUserRole === "admin" ||
      newUserRole === "internal admin" ||
      newUserRole === "superadmin" ||
      newUserRole === "developer")
  ) {
    return false;
  }
  if (
    creatorRole === "internal admin" &&
    (newUserRole === "superadmin" || newUserRole === "developer")
  ) {
    return false;
  }

  return true;
};

export default isPermitted;
