async function SendEmail({ to, subject, html }) {
  const url =
    import.meta.env.MODE === "production"
      ? import.meta.env.VITE_PRD_SERVER_URL
      : import.meta.env.VITE_DEV_SERVER_URL;

  try {
    const response = await fetch(`${url}/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ to, subject, html }),
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.error);
    }

    return result;
  } catch (error) {
    throw new Error("Failed to send email: " + error.message);
  }
}

export default SendEmail;
