import styles from "./ReactSelect.module.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PropType from "prop-types";

function ReactSelect({
  options,
  placeholder,
  noOptionsMsg,
  label,
  boldLabel,
  marginBottom,
  hasDefault,
  required,
  onChange,
  value,
}) {
  // define custom theme for the select
  function customTheme(theme) {
    return {
      ...theme,
      colors: { ...theme.colors, primary25: "#ecf0f1", primary: "#000" },
      spacing: {
        controlHeight: 28,
        menuGutter: 10,
        baseUnit: 2,
      },
    };
  }

  return (
    <div>
      <p className={`${styles.customLabel} ${boldLabel ? styles.bold : ""}`}>
        {label}
      </p>

      <Select
        isMulti
        required={required}
        defaultValue={hasDefault ? value : null}
        components={makeAnimated()}
        isSearchable
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMsg}
        theme={customTheme}
        options={options}
        value={value}
        onChange={onChange}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: "33px",
            minWidth: "180px",
            display: "flex",
            alignItems: "center",
            marginBottom: marginBottom,
            border: "1px solid var(--color-light-gray)",
            fontFamily: "inherit",
            fontSize: "1.1rem", // this is the size that is the closest to the default size. 'inherit' is too small
            borderRadius: "4px",
          }),
          valueContainer: (base) => ({
            ...base,
            minHeight: "33px",
            display: "flex",
            alignItems: "center",
            padding: "6px 8px", // Ensure padding is applied to center contents
          }),
          input: (base) => ({
            ...base,
            margin: "0px",
            padding: "0px",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            minHeight: "33px",
            display: "flex",
            alignItems: "center",
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: "0px 8px", // Ensure padding is applied for consistent spacing
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "0px 8px", // Ensure padding is applied for consistent spacing
          }),
          singleValue: (base) => ({
            ...base,
            margin: "0px",
            padding: "0px",
            fontSize: "140px",
          }),
        }}
      />
    </div>
  );
}

ReactSelect.propTypes = {
  options: PropType.array.isRequired,
  placeholder: PropType.string,
  noOptionsMsg: PropType.string,
  label: PropType.string.isRequired,
  boldLabel: PropType.bool,
  hasDefault: PropType.bool,
  marginBottom: PropType.string,
  required: PropType.bool,
  value: PropType.array,
  onChange: PropType.func,
};

export default ReactSelect;
