import styles from "./Settings.module.css";
import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactSelect from "../components/ui/elements/custom/ReactSelect";
import { supabase } from "../supabase/client";
import { toast } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import Modal from "../components/ui/elements/custom/Modal";

function Settings() {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [name, setName] = useState("");
  const [api_key, setApi_key] = useState("");
  const [url, setUrl] = useState("");

  const getAllUsers = async () => {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("id, full_name, email, role_id!inner(role)")
        .in("role_id.role", ["superadmin", "developer", "internal admin"])
        .order("full_name");

      if (error) {
        throw new Error("Failed to get user list: " + error.message);
      }

      setAllUsers(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getServiceProviders = async () => {
    // Get all service providers
    try {
      const { data, error } = await supabase
        .from("api_service_providers")
        .select("id, name, api_key, url")
        .order("name");

      if (error) {
        throw new Error("Failed to get service providers: " + error.message);
      }

      setServiceProviders(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getSyncUpdateReceipients = async () => {
    try {
      const { data, error } = await supabase
        .from("sync_update_receipients")
        .select("user_id");

      if (error) {
        throw new Error(
          "Failed to get sync update receipients: " + error.message
        );
      }

      return data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateSyncUpdateReceipients = async () => {
    if (selectedUsers.length === 0) {
      toast.error("Please select at least one user");
      return;
    }
    try {
      const { data, error } = await supabase
        .from("sync_update_receipients")
        .delete()
        .neq("id", -1);

      if (error) {
        throw new Error(
          "Failed to update sync update receipients: " + error.message
        );
      }

      const insertData = selectedUsers.map((u) => ({ user_id: u.value }));

      const { data: insertDataResult, error: insertError } = await supabase
        .from("sync_update_receipients")
        .insert(insertData);

      if (insertError) {
        throw new Error(
          "Failed to update sync update receipients: " + insertError.message
        );
      }

      toast.success("Sync update recipients updated successfully!");

      return data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdateSelectedUsers = () => {
    updateSyncUpdateReceipients().then(() => {
      // toast.success("Sync update receipients updated successfully");
    });
  };

  const handleOpenModal = (modal) => setActiveModal(modal);

  const handleCloseModal = () => setActiveModal(false);

  const handleAddNewProvider = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const name = formData.get("name");
    const api_key = formData.get("api_key");
    const url = formData.get("url");

    if (
      !name ||
      !api_key ||
      !url ||
      name.trim() === "" ||
      api_key.trim() === "" ||
      url.trim() === ""
    ) {
      toast.error("All fields are required");
      return;
    }

    try {
      const { data, error } = await supabase
        .from("api_service_providers")
        .insert([
          {
            name,
            api_key,
            url,
          },
        ]);

      if (error) {
        throw new Error("Failed to add new service provider: " + error.message);
      }

      toast.success("New service provider added successfully");

      getServiceProviders();

      setActiveModal(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditProvider = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const name = formData.get("name");
    const api_key = formData.get("api_key");
    const url = formData.get("url");

    if (
      !name ||
      !api_key ||
      !url ||
      name.trim() === "" ||
      api_key.trim() === "" ||
      url.trim() === ""
    ) {
      toast.error("All fields are required");
      return;
    }

    try {
      const { data, error } = await supabase
        .from("api_service_providers")
        .update({
          name,
          api_key,
          url,
        })
        .eq("id", selectedProvider.id);

      if (error) {
        throw new Error("Failed to update service provider: " + error.message);
      }

      toast.success("Service provider updated successfully");

      getServiceProviders();

      setActiveModal(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteProvider = async (e) => {
    e.preventDefault();

    try {
      const { data, error } = await supabase
        .from("api_service_providers")
        .delete()
        .eq("id", selectedProvider.id);

      if (error) {
        throw new Error("Failed to delete service provider: " + error.message);
      }

      toast.success("Service provider deleted successfully");

      getServiceProviders();

      setActiveModal(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getAllUsers();
    getServiceProviders();
  }, []);

  useEffect(() => {
    if (allUsers.length === 0) return;

    getSyncUpdateReceipients().then((data) => {
      setSelectedUsers(
        data.map((h) => ({
          value: h.user_id,
          label: allUsers.find((u) => u.id === h.user_id).full_name,
        }))
      );
    });
  }, [allUsers]);

  useEffect(() => {}, [allUsers, selectedUsers]);

  useEffect(() => {
    if (selectedProvider) {
      setName(selectedProvider.name);
      setApi_key(selectedProvider.api_key);
      setUrl(selectedProvider.url);
    }
  }, [selectedProvider]);

  return (
    <>
      <div className={styles.main_container}>
        <h4 className={styles.page_title}>Application Settings</h4>
        <div className={styles.form_container}>
          <Tabs>
            <TabList>
              <Tab>Database Update Notification</Tab>
              <Tab>API Service Providers</Tab>
              {/* <Tab>Notifications</Tab>
              <Tab>Privacy</Tab> */}
            </TabList>

            <TabPanel>
              {/* <h4>Manage Roles</h4> */}
              <p className={styles.info}>
                Choose which users receive email notification of database update
                attempts
              </p>
              <ReactSelect
                marginBottom="24px"
                options={
                  allUsers
                    ? allUsers.map((u) => ({
                        value: u.id,
                        label: u.full_name,
                      }))
                    : []
                }
                value={selectedUsers}
                isMulti={true}
                onChange={(selected) => setSelectedUsers(selected)}
                boldLabel={true}
                placeholder="Select users"
                noOptionsMsg="No users available"
              />
              <div className={styles.btn_container}>
                <button
                  className={styles.float_right}
                  onClick={handleUpdateSelectedUsers}
                >
                  Update receipients list
                </button>
              </div>
            </TabPanel>
            <TabPanel>
              {/* <h2>Security Settings</h2> */}
              <p className={styles.info}>Manage service providers</p>
              <div className={styles.btn_container}>
                <button onClick={() => handleOpenModal("newProviderModal")}>
                  New provider
                </button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>API Key</th>
                    <th>URL</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceProviders.map((sp) => (
                    <tr key={sp.id}>
                      <td>{sp.name}</td>
                      <td>{sp.api_key}</td>
                      <td>{sp.url}</td>
                      <td className={styles.action_column}>
                        <button
                          className={styles.action_btns}
                          title="Edit"
                          onClick={() => {
                            setSelectedProvider(sp);
                            handleOpenModal("editProviderModal");
                          }}
                        >
                          <FiEdit /> Edit
                        </button>
                        <button
                          className={styles.action_btns}
                          title="Delete"
                          onClick={() => {
                            setSelectedProvider(sp);
                            handleOpenModal("deleteProviderModal");
                          }}
                        >
                          <MdDeleteOutline /> Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Modal to add new provider */}
              <Modal
                isOpen={activeModal === "newProviderModal"}
                onClose={handleCloseModal}
              >
                <h4>Adding new provider</h4>
                <form onSubmit={handleAddNewProvider}>
                  <div className={styles.form_container}>
                    <label htmlFor="name" className={styles.labels}>
                      Name
                    </label>
                    <input
                      required
                      type="text"
                      placeholder="Provider name"
                      id="name"
                      name="name"
                      className={styles.textboxes}
                    />
                    <label htmlFor="api_key" className={styles.labels}>
                      API Key
                    </label>
                    <input
                      required
                      type="text"
                      placeholder="e27b9d8a7f46402c80ff4f5aeb1e5c434b9ca8e2a3e14d64b79e04c81aacd963"
                      id="api_key"
                      name="api_key"
                      className={styles.textboxes}
                    />

                    <label htmlFor="url" className={styles.labels}>
                      URL
                    </label>
                    <input
                      required
                      type="url"
                      placeholder="https://randomapiurl.me/api/"
                      id="url"
                      name="url"
                      className={styles.textboxes}
                    />
                    <div className={styles.btn_container}>
                      <button type="submit">Add provider</button>
                    </div>
                  </div>
                </form>
              </Modal>

              {/* Modal to update selected provider */}
              <Modal
                isOpen={activeModal === "editProviderModal"}
                onClose={handleCloseModal}
              >
                <h4>Editing {name}</h4>
                <form onSubmit={handleEditProvider}>
                  <div className={styles.form_container}>
                    <label htmlFor="name" className={styles.labels}>
                      Name
                    </label>
                    <input
                      required
                      type="text"
                      placeholder="Provider name"
                      id="name"
                      name="name"
                      className={styles.textboxes}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="api_key" className={styles.labels}>
                      API Key
                    </label>
                    <input
                      required
                      type="text"
                      placeholder="e27b9d8a7f46402c80ff4f5aeb1e5c434b9ca8e2a3e14d64b79e04c81aacd963"
                      id="api_key"
                      name="api_key"
                      className={styles.textboxes}
                      value={api_key}
                      onChange={(e) => setApi_key(e.target.value)}
                    />

                    <label htmlFor="url" className={styles.labels}>
                      URL
                    </label>
                    <input
                      required
                      type="url"
                      placeholder="https://randomapiurl.me/api/"
                      id="url"
                      name="url"
                      className={styles.textboxes}
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                    <div className={styles.btn_container}>
                      <button type="submit">Update provider</button>
                    </div>
                  </div>
                </form>
              </Modal>

              {/* Modal to delete selected provider */}
              <Modal
                isOpen={activeModal === "deleteProviderModal"}
                onClose={handleCloseModal}
              >
                <h4>Deleting {name}</h4>
                <form onSubmit={handleDeleteProvider}>
                  <div className={styles.form_container}>
                    <p>
                      You are about to delete {name}. This action is not
                      reversible. Are you sure you want to delete {name}?
                    </p>
                    <div className={styles.btn_container}>
                      <button type="submit">Delete provider</button>
                    </div>
                  </div>
                </form>
              </Modal>
            </TabPanel>
            <TabPanel>
              <h2>Notifications Settings</h2>
              <p>Notifications settings content</p>
            </TabPanel>
            <TabPanel>
              <h2>Privacy Settings</h2>
              <p>Privacy settings content</p>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Settings;
