function compactNum(number, digits) {
  // Handle non-numerics
  if (typeof number !== "number") return NaN;

  const units = [
    { value: 1e12, symbol: "T" }, // Trillion
    { value: 1e9, symbol: "B" }, // Billion
    { value: 1e6, symbol: "M" }, // Million
    { value: 1e3, symbol: "K" }, // Thousand
  ];

  // Handle zero
  if (number === 0) return "0";

  // Handle negative numbers
  const sign = number < 0 ? "-" : "";
  number = Math.abs(number);

  // Main logic
  for (let i = 0; i < units.length; i++) {
    if (number >= units[i].value) {
      return sign + (number / units[i].value).toFixed(digits) + units[i].symbol;
    }
  }

  // For numbers less than 1000
  return sign + number.toFixed(digits);
}

export default compactNum;
