import styles from "./Scorecard.module.css";
import compactNum from "../../../../utils/CompactNumber";
import { RiHotelBedLine } from "react-icons/ri";
import { FaDollarSign, FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MdOutlineToday } from "react-icons/md";
import { RxDividerVertical } from "react-icons/rx";

// Helper function to calculate the percentage difference
const calculatePercentageDifference = (current, previous) => {
  if (previous === 0) return current > 0 ? 100 : 0; // Handle zero division case

  return (((current - previous) / previous) * 100).toFixed(1);
};

// Helper function to render the appropriate icon
const renderIcon = (iconType) => {
  const icons = {
    hotel: <RiHotelBedLine />,
    money: <FaDollarSign />,
    calendar: <MdOutlineToday />,
  };
  return icons[iconType] || null;
};

const displayValue = (value, prefix, suffix) => {
  if (value === 0) return "--";
  return value
    ? `${prefix ? prefix : ""}${compactNum(value, 2)}${suffix ? suffix : ""}`
    : "--";
};

function Scorecard({
  title,
  value,
  compareValue,
  prefix,
  suffix,
  icon,
  compareMode,
}) {
  const percentageDifference = calculatePercentageDifference(
    value,
    compareValue
  );
  const isGain = value > compareValue;

  return (
    <div className={styles.scorecard}>
      <h4 className={styles.title}>
        {renderIcon(icon)}
        {title}
      </h4>
      <p className={styles.value_and_compare}>
        <span className={styles.value}>
          {displayValue(value, prefix, suffix)}
        </span>
        <RxDividerVertical className={styles.divider} />
        <span className={styles.compare_value}>
          {displayValue(compareValue, prefix, suffix)}
        </span>
      </p>
      <span className={styles.footer}>
        <span
          className={`${styles.compare} ${isGain ? styles.gain : styles.loss}`}
        >
          {isGain ? (
            <FaArrowUp className={styles.icon} />
          ) : (
            <FaArrowDown className={styles.icon} />
          )}
          &nbsp;{Math.abs(percentageDifference)}%
        </span>
        <span className={styles.info}>
          &nbsp;vs.{" "}
          {compareMode === "date"
            ? "same period last month"
            : "your competitive set"}
        </span>
      </span>
    </div>
  );
}

export default Scorecard;
