import {
  Bar,
  BarChart,
  XAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  YAxis,
  Legend,
  CartesianGrid,
} from "recharts";
import { differenceInDays, format } from "date-fns";
import { RxDotFilled, RxDividerVertical } from "react-icons/rx";
import styles from "./TimelineChart.module.css";
import { useState, useMemo } from "react";
import { mean, sum } from "mathjs";
import compactNum from "../../../../utils/CompactNumber";

// Helper function to calculate the selected metric value based on data
const calculateMetric = (data, metric, isValue) => {
  if (!data?.length) return 0;
  const metricKey = getMetricKey(metric, isValue);
  const validValues = data
    .map((record) => record[metricKey])
    .filter((value) => value != null && !isNaN(value));
  if (!validValues.length) return "--";
  return formatMetricValue(metric, validValues);
};

// Helper function to map metric name to specific field key in data
const getMetricKey = (metric, isValue) => {
  const metrics = {
    Occupancy: isValue ? "occupancy" : "prevOccupancy",
    ADR: isValue ? "ADR" : "prevADR",
    "Total Revenue": isValue ? "totalRevenue" : "prevTotalRevenue",
    RevPAR: isValue ? "revPAR" : "prevRevPAR",
  };
  return metrics[metric] || null;
};

// Helper function to format metric values based on the type of metric
const formatMetricValue = (metric, values) => {
  const metricFormatter = {
    "Total Revenue": () => `$${compactNum(sum(values), 2)}`,
    Occupancy: () => `${compactNum(mean(values), 2)}%`,
    default: () => `$${compactNum(mean(values), 2)}`,
  };
  return (metricFormatter[metric] || metricFormatter.default)();
};

// Function to determine max value based on selected metric
const getMaxValue = (metric, maxValues) => {
  const metricMax = {
    Occupancy: 100,
    ADR: maxValues?.maxADR || 0,
    "Total Revenue": maxValues?.maxTotalRevenue || 0,
    RevPAR: maxValues?.maxRevPAR || 0,
  };
  return metricMax[metric] || 100;
};

function TimelineChart({
  data,
  selectedDate,
  targetDate,
  maxValues,
  selectedHotel,
  selectedMonth,
  compareMode,
}) {
  const [selectedMetric, setSelectedMetric] = useState("Occupancy");

  // Memoized metric calculations to optimize performance
  const metricValue = useMemo(
    () => calculateMetric(data, selectedMetric, true),
    [data, selectedMetric]
  );
  const metricCompareValue = useMemo(
    () => calculateMetric(data, selectedMetric, false),
    [data, selectedMetric]
  );
  const maxValue = useMemo(
    () => getMaxValue(selectedMetric, maxValues),
    [selectedMetric, maxValues]
  );

  // Simplified tooltip rendering for enhanced readability
  const renderTooltipContent = ({ label, payload }) => {
    if (!payload?.length) return null; // Return null if no data
    const formattedLabel = format(Date.parse(label), "eeee dd MMMM yyyy"); // Format date label
    return (
      <div className={styles.custom_tooltip}>
        <h4 className={styles.tooltip_label}>{formattedLabel}</h4>
        {payload.reverse().map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{ color: entry.color }}
            className={styles.tooltip_item}
          >
            {`${entry.name}: ${
              selectedMetric === "Occupancy"
                ? `${compactNum(entry.value, 2)}%`
                : `$${compactNum(entry.value, 2)}`
            }`}
          </p>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.chart_title}>
          {/* Display selected metric and values */}
          <h4 className={styles.metric}>{selectedMetric}</h4>
          <h2 className={styles.avg_value}>
            {metricValue} <RxDividerVertical className={styles.divider} />
            <span className={styles.compare_value}>{metricCompareValue}</span>
          </h2>
          <p className={styles.date_info}>
            {selectedHotel}
            <RxDividerVertical />
            {selectedMonth}
            <span className={styles.days_to_end}>
              <RxDotFilled /> {selectedDate && format(selectedDate, "PPP")}
              <RxDividerVertical />
              {differenceInDays(targetDate, selectedDate)} days left
            </span>
          </p>
        </div>

        {/* Metric selection dropdown */}
        <div className={styles.chart_filter}>
          <select
            id="metrics"
            className={styles.textboxes}
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
          >
            {["Occupancy", "ADR", "Total Revenue", "RevPAR"].map((metric) => (
              <option key={metric} value={metric}>
                {metric}
              </option>
            ))}
          </select>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={data}
          margin={{ top: 15, right: 30, left: 15, bottom: 60 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="0.2" />
          <Legend
            align="right"
            verticalAlign="top"
            iconType="circle"
            wrapperStyle={{
              paddingBottom: "40px",
            }}
          />
          <Tooltip content={renderTooltipContent} />

          <YAxis
            dataKey={getMetricKey(selectedMetric, true)}
            domain={[0, maxValue]}
            tickFormatter={(value) =>
              selectedMetric === "Occupancy"
                ? value + "%"
                : `$${compactNum(value, 0)}`
            }
            axisLine={false}
            tickLine={false}
            tickMargin={10}
          />

          <XAxis
            dataKey="date"
            xAxisId="value"
            name="Date"
            tickFormatter={(value) => format(Date.parse(value), "eee dd-MM")}
            angle={325}
            textAnchor="end"
            dy={5}
            fontSize="0.9rem"
            type="category"
          />
          <XAxis dataKey="prevDate" xAxisId="prev" hide name="Date" />

          {/* Previous month/comparison bar */}
          <Bar
            dataKey={getMetricKey(selectedMetric, false)}
            fill="var(--color-blue)"
            opacity={0.6}
            xAxisId="prev"
            name={
              compareMode === "date"
                ? `${selectedMetric} (previous month)`
                : `${selectedMetric} (competitive set)`
            }
          >
            <LabelList
              dataKey={getMetricKey(selectedMetric, false)}
              position="top"
              formatter={(value) =>
                selectedMetric === "Occupancy"
                  ? `${compactNum(value, 1)}%`
                  : `$${compactNum(value, 1)}`
              }
              fontSize="0.8rem"
              fill="var(--color-blue)"
            />
          </Bar>

          {/* Current value bar */}
          <Bar
            dataKey={getMetricKey(selectedMetric, true)}
            xAxisId="value"
            fill="var(--color-red)"
            opacity={0.6}
            name={selectedMetric}
          >
            <LabelList
              dataKey={getMetricKey(selectedMetric, true)}
              position="top"
              fontSize="0.8rem"
              fill="var(--color-red)"
              formatter={(value) =>
                selectedMetric === "Occupancy"
                  ? `${compactNum(value, 1)}%`
                  : `$${compactNum(value, 1)}`
              }
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default TimelineChart;
