import styles from "./NewHotel.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { supabase } from "../supabase/client";
import { IoChevronForwardSharp } from "react-icons/io5";

function NewHotel() {
  const [hotelName, setHotelName] = useState("");
  const [hotelCode, setHotelCode] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [apiId, setApiId] = useState("");

  const [providers, setProviders] = useState([]);

  const navigate = useNavigate();

  const fetchProviders = async () => {
    try {
      const { data, error } = await supabase
        .from("api_service_providers")
        .select();

      if (error) {
        throw error;
      }

      setProviders(data);
    } catch (error) {
      toast.error("Failed to fetch API providers: " + error);
      return null;
    }
  };

  const handleInsert = async (e) => {
    e.preventDefault();

    if (!hotelName || !hotelCode || !serviceProvider || !apiId) {
      alert("Name, Code, Provider, and Resort Code are required fields.");
      return;
    }

    toast.promise(
      supabase
        .from("hotels")
        .insert({
          name: hotelName.trim(),
          code: hotelCode.trim(),
          api_provider_id: serviceProvider.trim(),
          active: true,
          api_identifier: apiId.trim(),
        })
        .then(({ data, error }) => {
          if (error) throw error;
          return data;
        }),
      {
        pending: `Adding ${hotelName}...`,
        success: {
          render() {
            navigate("/hotels");
            return `${hotelName} added successfully!`;
          },
        },
        error: {
          render({ data }) {
            return (
              <>
                <p>Failed to add {hotelName}!</p>
                <p className={styles.info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      }
    );
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <>
      <div className={styles.main_container}>
        <h4 className={styles.page_title}>
          Hotels <IoChevronForwardSharp /> New
        </h4>

        <form onSubmit={handleInsert}>
          <div className={styles.form_container}>
            <label htmlFor="hotel_name" className={styles.labels}>
              Hotel name
            </label>
            <input
              required
              type="text"
              placeholder="Hotel name"
              id="hotel_name"
              name="hotel_name"
              className={styles.textboxes}
              onChange={(e) => setHotelName(e.target.value)}
              value={hotelName}
            />
            <label htmlFor="code" className={styles.labels}>
              Code
            </label>
            <input
              required
              type="text"
              placeholder="Hotel code"
              id="code"
              name="code"
              className={styles.textboxes}
              onChange={(e) => setHotelCode(e.target.value)}
              value={hotelCode}
            />
            <label htmlFor="provider" className={styles.labels}>
              Provider
            </label>
            <select
              className={styles.textboxes}
              name="provider"
              id="provider"
              required
              onChange={(e) => setServiceProvider(e.target.value)}
              value={serviceProvider}
            >
              <option value="">--</option>
              {providers.map((provider) => (
                <option key={provider.id} value={provider.id}>
                  {provider.name}
                </option>
              ))}
            </select>
            <label htmlFor="api_id" className={styles.labels}>
              Resort Code
            </label>
            <input
              required
              type="number"
              placeholder="10"
              id="api_id"
              name="api_id"
              className={styles.textboxes}
              onChange={(e) => setApiId(e.target.value)}
              value={apiId}
            />
            <div className={styles.fn_btns}>
              <button
                onClick={() => navigate("/hotels")}
                title="Return to client list"
                className="neutral_btn"
              >
                Cancel
              </button>

              <button type="submit" title="Add hotel">
                Create hotel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewHotel;
