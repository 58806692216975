// useAuth.js
import { toast } from "react-toastify";
import { supabase } from "../supabase/client";
import { useUser } from "./userContext";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const { updateUser } = useUser();
  const navigate = useNavigate();

  const loginUser = async (email, password) => {
    try {
      // Don't log in blocked users
      const status = await userIsActive(email);

      if (!status) {
        toast.error(
          "Your account has been blocked. Contact the administrator for details"
        );

        return;
      }

      // If not blocked, continue
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        throw error;
      }

      const { session, user } = data;
      const { data: userProfile } = await supabase
        .from("users")
        .select(
          "id, first_name, last_name, full_name, email, active, created_at, last_sign_in, auth_user_id, role_id(role), user_hotel (hotels(name, code, id))"
        )
        .eq("auth_user_id", user.id)
        .single();

      updateUser({ session, profile: userProfile });

      navigate("/");
    } catch (error) {
      toast.error(`Log in failed! Reason: ${error.message}`);
    }
  };

  const logoutUser = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw error;
      }

      updateUser({ session: null, profile: null });
      navigate("/login");
    } catch (error) {
      console.error("Logout failed: ", error);
      toast.error("Logout failed: " + error.message);
    }
  };

  const userIsActive = async (email) => {
    const url =
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_PRD_SERVER_URL
        : import.meta.env.VITE_DEV_SERVER_URL;

    try {
      const userStatus = await fetch(`${url}/fetch-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const { active } = await userStatus.json();

      return active;
    } catch (error) {
      console.log(error.message);
    }
  };

  return { loginUser, logoutUser };
};

export default useAuth;
