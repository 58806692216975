import styles from "./Analytics.module.css";
import Scorecard from "../components/ui/elements/custom/Scorecard";
import TimelineChart from "../components/ui/elements/custom/TimelineChart";
import { useUser } from "../auth/userContext";
import { useEffect, useState, useCallback } from "react";
import {
  format,
  parseISO,
  subDays,
  startOfMonth,
  endOfMonth,
  isWithinInterval,
  formatDistanceToNow,
  min,
  max,
  set,
} from "date-fns";
import { supabase } from "../supabase/client";
import { toast } from "react-toastify";
import { divide, mean, sum, max as mathMax } from "mathjs";
import Loader from "../components/ui/elements/custom/Loader";
import {
  HiMiniBackward,
  HiMiniForward,
  HiMiniPlay,
  HiMiniPause,
} from "react-icons/hi2";

// Utility function for safe division
const safeDivide = (numerator, denominator) => {
  if (denominator === 0 || !denominator) return 0;
  return divide(numerator, denominator);
};

function Analytics() {
  const { userProfile } = useUser();
  const [allHotels, setAllHotels] = useState([]);
  const [hotelFacts, setHotelFacts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filteredPrev, setFilteredPrev] = useState([]);
  const [hotel, setHotel] = useState("");
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [minMonth, setMinMonth] = useState("");
  const [maxMonth, setMaxMonth] = useState("");

  const [targetDate, setTargetDate] = useState("");
  const [sliderDateRange, setSliderDateRange] = useState([]);
  const [selectedSliderIndex, setSelectedSliderIndex] = useState(0);
  const [latestUpdateTime, setLatestUpdateTime] = useState();
  const [occupancy, setOccupancy] = useState(0);
  const [prevOccupancy, setPrevOccupancy] = useState(0);
  const [ADR, setADR] = useState(0);
  const [prevADR, setPrevADR] = useState(0);
  const [revPAR, setRevPAR] = useState(0);
  const [prevRevPAR, setPrevRevPAR] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [prevTotalRevenue, setPrevTotalRevenue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Fetch all active hotels
  const fetchAllHotels = async () => {
    try {
      const { data, error } = await supabase
        .from("hotels")
        .select("id, name, code, active")
        .eq("active", true)
        .order("code");
      if (error) throw new Error("Failed to fetch all hotels");
      setAllHotels(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Fetch hotel facts for a specific hotel
  const fetchHotelFacts = useCallback(async (hotelId) => {
    if (!hotelId) {
      setIsLoading(false);

      toast.warn("No hotels are associated with your profile.");
      return;
    }

    // Clear previous data when a new hotel is selected
    setHotelFacts([]);
    setFiltered([]);
    setChartData([]);

    setIsLoading(true);

    try {
      const { data, error } = await supabase
        .from("hotel_facts")
        .select()
        .eq("hotel_id", hotelId)
        .order("created_at")
        .order("date");

      if (error)
        throw new Error("Failed to fetch hotel data: " + error.message);
      if (data.length === 0) {
        setIsLoading(false);

        toast.warn("No data available for the selected hotel.");
        return;
      }

      setHotelFacts(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handlePrevious = () => {
    setIsPlaying(false);
    setSelectedSliderIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setIsPlaying(false);
    setSelectedSliderIndex((prevIndex) =>
      Math.min(prevIndex + 1, sliderDateRange.length - 1)
    );
  };

  const handleAutoPlay = useCallback(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setSelectedSliderIndex((prevIndex) => {
          if (prevIndex < sliderDateRange.length - 1) return prevIndex + 1;
          clearInterval(interval);
          setIsPlaying(false);
          return prevIndex;
        });
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isPlaying, sliderDateRange.length]);

  const handleMonthChange = (e) => {
    const pattern = /^\d{4}-\d{2}$/;
    if (
      e.target.value &&
      e.target.value !== "" &&
      pattern.test(e.target.value)
    ) {
      setSelectedMonth(parseISO(e.target.value));
    } else {
      toast.info(
        "Invalid date format or empty date. Please select a valid month."
      );
    }
  };

  // Effect to fetch all hotels on component mount
  useEffect(() => {
    fetchAllHotels();
  }, []);

  // Effect to handle hotel selection and fetch facts
  useEffect(() => {
    if (allHotels.length > 0 && userProfile) {
      // If no hotel is selected, select the first one based on the user's role
      if (!hotel) {
        const userRole = userProfile?.role_id.role;
        const defaultHotel =
          userRole === "developer" || userRole === "superadmin"
            ? allHotels[0]?.id
            : userProfile?.user_hotel[0]?.hotels?.id || "";
        setHotel(defaultHotel);
      } else {
        // Fetch hotel facts if a hotel is selected
        fetchHotelFacts(hotel);
      }
    }
  }, [hotel, allHotels, fetchHotelFacts]);

  // Effect to filter hotel facts and update target date based on selected month
  useEffect(() => {
    if (hotelFacts.length > 0 && selectedMonth) {
      const startDate = startOfMonth(new Date(selectedMonth));
      const endDate = endOfMonth(startDate);

      setTargetDate(endDate);

      const filteredCurrent = hotelFacts.filter((record) =>
        isWithinInterval(parseISO(record.date), {
          start: startDate,
          end: endDate,
        })
      );

      setFiltered(filteredCurrent);

      // TODO: Uncheck this block to return comparison to previous year

      // const prevYearStartDate = subDays(startDate, 360);
      // const prevYearEndDate = subDays(endDate, 360);

      // const filteredPrevious = hotelFacts.filter((record) =>
      //   isWithinInterval(parseISO(record.date), {
      //     start: prevYearStartDate,
      //     end: prevYearEndDate,
      //   })

      // End of block to uncomment

      // TODO: Remove this block after returing comparison data to previous year
      const prevMonthStartDate = subDays(startDate, 30);
      const prevMonthEndDate = subDays(endDate, 30);

      const previous = hotelFacts.filter(
        (record) =>
          isWithinInterval(parseISO(record.date), {
            start: prevMonthStartDate,
            end: prevMonthEndDate,
          })
        // End of block to remove
      );

      // Only get the latest created date for the previous data
      if (previous.length > 0) {
        const prevLatestCreatedDate = previous.reduce((latest, item) => {
          return latest > item.created_at ? latest : item.created_at;
        }, previous[0].created_at);

        const filteredPrevious = previous.filter(
          (r) =>
            format(r.created_at, "yyyy-MM-dd") ===
            format(prevLatestCreatedDate, "yyyy-MM-dd")
        );

        setFilteredPrev(filteredPrevious);
      }
    }
  }, [hotelFacts, selectedMonth]);

  // Effect to process filtered data
  useEffect(() => {
    const dateMap = new Map();

    filtered.forEach((currentRecord) => {
      // TODO: uncomment this block to return comparison data to previous year

      // const prevRecord = filteredPrev.find(
      //   (prevRecord) => format(parseISO(prevRecord.date), "yyyy-MM-dd") === format(subDays(parseISO(currentRecord.date), 360), "yyyy-MM-dd")
      // );

      // End of block to uncomment

      // TODO: Remove this block after returing comparison data to previous year
      const prevRecord = filteredPrev.find(
        (prevRecord) =>
          format(parseISO(prevRecord.date), "yyyy-MM-dd") ===
          format(subDays(parseISO(currentRecord.date), 30), "yyyy-MM-dd")
      );
      // End of block to remove

      const dateKey = `${parseISO(currentRecord.date)}_${format(
        parseISO(currentRecord.created_at),
        "yyyy-MM-dd"
      )}`;

      if (!dateMap.has(dateKey)) {
        dateMap.set(dateKey, {
          createdDate: format(parseISO(currentRecord.created_at), "yyyy-MM-dd"),
          createdDateTime: parseISO(currentRecord.created_at),
          date: parseISO(currentRecord.date),
          occupancy:
            safeDivide(
              currentRecord.rooms_occupied,
              currentRecord.rooms_total
            ) * 100,
          prevOccupancy: prevRecord
            ? safeDivide(prevRecord.rooms_occupied, prevRecord.rooms_total) *
              100
            : null,
          ADR: currentRecord.average_daily_rate,
          prevADR: prevRecord ? prevRecord.average_daily_rate : null,
          revPAR: safeDivide(
            currentRecord.revenue_total,
            currentRecord.rooms_total
          ),
          prevRevPAR: prevRecord
            ? safeDivide(prevRecord.revenue_total, prevRecord.rooms_total)
            : null,
          totalRevenue: currentRecord.revenue_total,
          prevTotalRevenue: prevRecord ? prevRecord.revenue_total : null,
        });
      } else {
        const existingRecord = dateMap.get(dateKey);

        existingRecord.occupancy =
          (existingRecord.occupancy * existingRecord.occupancyCount +
            safeDivide(
              currentRecord.rooms_occupied,
              currentRecord.rooms_total
            ) *
              100) /
          (existingRecord.occupancyCount + 1);
        existingRecord.occupancyCount += 1;

        existingRecord.ADR =
          (existingRecord.ADR * existingRecord.ADRCount +
            currentRecord.average_daily_rate) /
          (existingRecord.ADRCount + 1);
        existingRecord.ADRCount += 1;

        existingRecord.revPAR =
          (existingRecord.revPAR * existingRecord.revPARCount +
            safeDivide(
              currentRecord.revenue_total,
              currentRecord.rooms_total
            )) /
          (existingRecord.revPARCount + 1);
        existingRecord.revPARCount += 1;

        if (prevRecord) {
          existingRecord.prevOccupancy =
            (existingRecord.prevOccupancy * existingRecord.prevOccupancyCount +
              safeDivide(prevRecord.rooms_occupied, prevRecord.rooms_total) *
                100) /
            (existingRecord.prevOccupancyCount + 1);
          existingRecord.prevOccupancyCount += 1;

          existingRecord.prevADR =
            (existingRecord.prevADR * existingRecord.prevADRCount +
              prevRecord.average_daily_rate) /
            (existingRecord.prevADRCount + 1);
          existingRecord.prevADRCount += 1;

          existingRecord.prevRevPAR =
            (existingRecord.prevRevPAR * existingRecord.prevRevPARCount +
              safeDivide(prevRecord.revenue_total, prevRecord.rooms_total)) /
            (existingRecord.prevRevPARCount + 1);
          existingRecord.prevRevPARCount += 1;
        }

        existingRecord.totalRevenue += currentRecord.revenue_total;
        if (prevRecord) {
          existingRecord.prevTotalRevenue += prevRecord.revenue_total;
        }
      }
    });

    const combinedData = Array.from(dateMap.values());
    combinedData
      .sort((a, b) => new Date(a.date) - new Date(b.date)) // this sort forces the date axis in ascending order
      .sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate)); // this sort forces the created date on the range slider to ascensing order

    setData(combinedData);

    if (combinedData.length > 0) {
      const latestDataUpdateTime = combinedData.reduce((latest, item) => {
        return latest > item.createdDateTime ? latest : item.createdDateTime;
      }, combinedData[0].createdDateTime);

      setLatestUpdateTime(latestDataUpdateTime);

      const scoreCardsData = combinedData.filter(
        (r) => r.createdDate === format(latestDataUpdateTime, "yyyy-MM-dd")
      );

      setOccupancy(
        mean(
          scoreCardsData
            .map((record) => record.occupancy)
            .filter((value) => value !== null && value !== undefined)
        )
      );

      setPrevOccupancy(
        scoreCardsData
          .map((record) => record.prevOccupancy)
          .filter((value) => value !== null && value !== undefined).length > 0
          ? mean(
              scoreCardsData
                .map((record) => record.prevOccupancy)
                .filter((value) => value !== null && value !== undefined)
            )
          : 0
      );

      setADR(
        mean(
          scoreCardsData
            .map((record) => record.ADR)
            .filter((value) => value !== null && value !== undefined)
        )
      );

      setPrevADR(
        scoreCardsData
          .map((record) => record.prevADR)
          .filter((value) => value !== null && value !== undefined).length > 0
          ? mean(
              scoreCardsData
                .map((record) => record.prevADR)
                .filter((value) => value !== null && value !== undefined)
            )
          : 0
      );

      setRevPAR(
        mean(
          scoreCardsData
            .map((record) => record.revPAR)
            .filter((value) => value !== null && value !== undefined)
        )
      );

      setPrevRevPAR(
        scoreCardsData
          .map((record) => record.prevRevPAR)
          .filter((value) => value !== null && value !== undefined).length > 0
          ? mean(
              scoreCardsData
                .map((record) => record.prevRevPAR)
                .filter((value) => value !== null && value !== undefined)
            )
          : 0
      );

      setTotalRevenue(
        sum(
          scoreCardsData
            .map((record) => record.totalRevenue)
            .filter((value) => value !== null && value !== undefined)
        )
      );

      setPrevTotalRevenue(
        sum(
          scoreCardsData
            .map((record) => record.prevTotalRevenue)
            .filter((value) => value !== null && value !== undefined)
        )
      );
    } else {
      setOccupancy(0);
      setPrevOccupancy(0);
      setADR(0);
      setPrevADR(0);
      setRevPAR(0);
      setPrevRevPAR(0);
      setTotalRevenue(0);
      setPrevTotalRevenue(0);
    }
  }, [filtered, filteredPrev]);

  useEffect(() => {
    const uniqueCreatedDates = [
      ...new Set(data.map((record) => record.createdDate)),
    ];

    setSliderDateRange(uniqueCreatedDates);
  }, [data]);

  useEffect(() => {
    setChartData(
      data
        .filter(
          (record) =>
            record.createdDate === sliderDateRange[selectedSliderIndex]
        )
        .sort((a, b) => new Date(a.date) - new Date(b.date))
    );
  }, [sliderDateRange, selectedSliderIndex, data]);

  useEffect(handleAutoPlay, [isPlaying, sliderDateRange.length]);

  useEffect(() => {
    if (sliderDateRange.length > 0) {
      setSelectedSliderIndex(sliderDateRange.length - 1);
    }
  }, [sliderDateRange.length, selectedMonth, hotel]);

  useEffect(() => {
    if (hotelFacts.length > 0) {
      setMinMonth(
        format(
          min(hotelFacts.map((r) => parseISO(r.date), "yyyy-MM")),
          "yyyy-MM"
        )
      );

      setMaxMonth(
        format(
          max(hotelFacts.map((r) => parseISO(r.date), "yyyy-MM")),
          "yyyy-MM"
        )
      );
    }
  }, [hotelFacts]);

  return (
    <div className={styles.main_container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.filters}>
            <select
              id="hotels"
              name="hotels"
              className={styles.textboxes}
              value={hotel}
              onChange={(e) => {
                setHotel(e.target.value); // Set hotel when a new one is selected
                setIsLoading(true);
              }}
            >
              {userProfile.user_hotel
                .slice() // Clone the array to avoid mutating the original
                .sort((a, b) => a.hotels.name.localeCompare(b.hotels.name)) // Sort the array by hotel name
                .map((uh) => (
                  <option key={uh.hotels.id} value={uh.hotels.id}>
                    {uh.hotels.name}
                  </option>
                ))}
            </select>

            <input
              type="month"
              min={minMonth}
              max={maxMonth}
              value={format(selectedMonth, "yyyy-MM")}
              onChange={(e) => handleMonthChange(e)}
              required
              pattern="\d{4}-\d{2}"
            />
          </div>

          <p className={styles.latest_update}>
            {latestUpdateTime &&
              `Last updated ${formatDistanceToNow(latestUpdateTime, {
                addSuffix: true,
              })}`}
          </p>
          <div className={styles.scorecard_grp}>
            <Scorecard
              title="Occupancy"
              value={occupancy}
              compareValue={prevOccupancy}
              suffix="%"
              icon="hotel"
              showValue
              compareMode="date"
            />
            <Scorecard
              title="ADR"
              value={ADR}
              compareValue={prevADR}
              prefix="$"
              icon="money"
              showValue
              compareMode="date"
            />
            <Scorecard
              title="RevPAR"
              value={revPAR}
              compareValue={prevRevPAR}
              prefix="$"
              icon="money"
              showValue
              compareMode="date"
            />
            <Scorecard
              title="Total Revenue"
              value={totalRevenue}
              compareValue={prevTotalRevenue}
              prefix="$"
              icon="money"
              showValue
              compareMode="date"
            />
          </div>

          <div className={styles.timelineChartContainer}>
            <TimelineChart
              compareMode={"date"}
              data={chartData}
              selectedDate={
                sliderDateRange[selectedSliderIndex] &&
                parseISO(sliderDateRange[selectedSliderIndex])
              }
              targetDate={targetDate}
              maxValues={
                data.length > 0 && {
                  maxADR: mathMax(
                    mathMax(data.map((r) => (r.ADR ? r.ADR : 0))),
                    mathMax(data.map((r) => (r.prevADR ? r.prevADR : 0)))
                  ),
                  maxRevPAR: mathMax(
                    mathMax(data.map((r) => (r.revPAR ? r.revPAR : 0))),
                    mathMax(data.map((r) => (r.prevRevPAR ? r.prevRevPAR : 0)))
                  ),
                  maxTotalRevenue: mathMax(
                    mathMax(
                      data.map((r) => (r.totalRevenue ? r.totalRevenue : 0))
                    ),
                    mathMax(
                      data.map((r) =>
                        r.prevTotalRevenue ? r.prevTotalRevenue : 0
                      )
                    )
                  ),
                }
              }
              selectedHotel={allHotels.filter((h) => h.id === hotel)[0].name}
              selectedMonth={format(selectedMonth, "MMMM yyyy")}
            />
            <input
              type="range"
              className={styles.timelineSlider}
              min={0}
              max={sliderDateRange.length - 1}
              value={selectedSliderIndex}
              onChange={(e) =>
                setSelectedSliderIndex(parseInt(e.target.value, 10))
              }
              title={`Currently at: ${sliderDateRange[selectedSliderIndex]}`}
            />
            <div className={styles.btnGrp}>
              <span>
                <button
                  className={`${styles.grpBtn} ${styles.backBtn}`}
                  onClick={handlePrevious}
                >
                  <HiMiniBackward />
                </button>
                <button
                  className={`${styles.grpBtn} ${styles.playBtn}`}
                  onClick={() => setIsPlaying(!isPlaying)}
                >
                  {isPlaying ? <HiMiniPause /> : <HiMiniPlay />}
                </button>
                <button
                  className={`${styles.grpBtn} ${styles.forwardBtn}`}
                  onClick={handleNext}
                >
                  <HiMiniForward />
                </button>
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Analytics;
