import { supabase } from "../supabase/client";

const getUserRole = async (roleId) => {
  try {
    const { data, error } = await supabase
      .from("roles")
      .select("role")
      .eq("id", roleId)
      .single();

    if (error) {
      throw new Error(error);
    }

    return data.role;
  } catch (error) {
    console.error("Error getting user role:", error);
    return null;
  }
};

export default getUserRole;
