import PropTypes from "prop-types";

function CheckPassword(input, options) {
  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const numericRegex = /[0-9]/;
  const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;

  let isValid = true;

  // Check for the minimum number of characters
  if (options.minLength && input.length < options.minLength) {
    isValid = false;
  }

  if (options.lowercase) {
    isValid = isValid && lowercaseRegex.test(input);
  }
  if (options.uppercase) {
    isValid = isValid && uppercaseRegex.test(input);
  }
  if (options.numeric) {
    isValid = isValid && numericRegex.test(input);
  }
  if (options.special) {
    isValid = isValid && specialRegex.test(input);
  }

  return isValid;
}

// Define the PropTypes for the options object
CheckPassword.propTypes = {
  input: PropTypes.string.isRequired,
  options: PropTypes.shape({
    minLength: PropTypes.number,
    lowercase: PropTypes.bool,
    uppercase: PropTypes.bool,
    numeric: PropTypes.bool,
    special: PropTypes.bool,
  }).isRequired,
};

export default CheckPassword;
