import styles from "./SignUp.module.css";
import { useEffect, useState } from "react";
import Logo from "../../components/ui/elements/custom/Logo";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Checkpassword from "../../utils/CheckPassword";
import Welcome from "../../emails/Welcome";
import { render } from "@react-email/components";
import SendEmail from "../../utils/SendEmail";
import useAuth from "../../auth/useAuth";

function SignUp() {
  const [authId, setAuthId] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [signupToken, setSignupToken] = useState("");
  const [lastSignIn, setLastSignIn] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [searchParams] = useSearchParams();
  const urlEmail = searchParams.get("email");
  const urlToken = searchParams.get("token");
  const urlEvent = searchParams.get("event");

  const { loginUser } = useAuth();

  const url =
    import.meta.env.MODE === "production"
      ? import.meta.env.VITE_PRD_SERVER_URL
      : import.meta.env.VITE_DEV_SERVER_URL;

  const getUser = async (email) => {
    try {
      const response = await fetch(`${url}/fetch-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const newUser = await response.json();

      if (newUser.error) {
        throw new Error(newUser.error);
      }

      setAuthId(newUser.auth_user_id);
      setEmail(newUser.email);
      setFirstName(newUser.first_name);
      setSignupToken(newUser.signup_email_token);
      setLastSignIn(newUser.last_sign_in);
    } catch (error) {
      toast.error(
        `Failed to retrieve user information! Reason: ${error.message}`
      );
    }
  };

  const changePassword = async () => {
    if (urlEvent !== "signUp" || lastSignIn) {
      toast.error("Wrong operation");
      return;
    }

    if (signupToken !== urlToken) {
      toast.error("Wrong token!");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password and confirm password fields must match!");
      return;
    }

    if (
      !Checkpassword(password, {
        minLength: 8,
        lowercase: true,
        uppercase: true,
        numeric: true,
        special: true,
      })
    ) {
      toast.error(
        "Passwords must be at least 8 characters long, and contain lowercase, uppercase, numeric and special characters."
      );
      return;
    }

    toast.promise(
      fetch(`${url}/update-user-password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ authId, password }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update user password");
          }
          return response.json();
        })
        .then((data) => data),
      {
        pending: "Finalizing your account setup...",
        success: {
          render({ data }) {
            try {
              loginUser(email, password);

              const html = render(<Welcome firstName={firstName} />);
              SendEmail({
                to: email,
                subject: "Welcome!",
                html: html,
              });
            } catch (error) {
              console.error(error);
            }

            return "Account setup complete!";
          },
        },
        error: {
          render({ data }) {
            return (
              <>
                <p>Account setup failed!!</p>
                <p className={styles.toast_info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      }
    );
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    changePassword();
  };

  useEffect(() => {
    if (urlEmail) {
      getUser(urlEmail);
    }
  }, [urlEmail]);

  return (
    <div className={styles.container}>
      <div className={styles.logo_and_form}>
        <div className={styles.logo_container}>
          <Logo
            src="/images/BENS-logo-white.png"
            alt="bens logo white"
            className={styles.logo}
          />
        </div>
        <div className={styles.login_form}>
          <h3 className={styles.page_title}>
            {urlEmail && urlEvent ? <>Welcome, {firstName}</> : null}
          </h3>
          <p className={styles.info}>
            Please choose a password for your account. Passwords must be at
            least 8 characters long, and contain lowercase, uppercase, numeric
            and special characters.
          </p>
          <form onSubmit={handleSignUp}>
            <label htmlFor="email" className={styles.labels}>
              Email
            </label>
            <input
              required
              type="email"
              placeholder="johndoe@email.com"
              id="email"
              className={styles.textboxes}
              value={email}
              disabled
            />
            <label htmlFor="password" className={styles.labels}>
              Password
            </label>
            <input
              required
              type="password"
              placeholder="Password"
              id="password"
              className={styles.textboxes}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="confirmPassword" className={styles.labels}>
              Confirm password
            </label>
            <input
              required
              type="password"
              placeholder="Confirm password"
              id="confirmPassword"
              className={styles.textboxes}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={styles.footer}>
              <button type="submit">Sign up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
