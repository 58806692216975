function GeneratePassword(length, options) {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numericChars = "0123456789";
  const specialChars = "!@#$%^&*()-_=+[]{}|;:'\",.<>?/`~";

  let allChars = "";
  let password = "";

  // Ensure at least one character from each selected type
  if (options.lowercase) {
    allChars += lowercaseChars;
    password += getRandomChar(lowercaseChars);
  }
  if (options.uppercase) {
    allChars += uppercaseChars;
    password += getRandomChar(uppercaseChars);
  }
  if (options.numeric) {
    allChars += numericChars;
    password += getRandomChar(numericChars);
  }
  if (options.special) {
    allChars += specialChars;
    password += getRandomChar(specialChars);
  }

  // Fill the rest of the password length with random characters from all selected types
  for (let i = password.length; i < length; i++) {
    password += getRandomChar(allChars);
  }

  // Shuffle the password to ensure the first few characters are not always fixed
  password = shuffleString(password);

  return password;
}

function getRandomChar(charSet) {
  const randomIndex = Math.floor(Math.random() * charSet.length);
  return charSet[randomIndex];
}

function shuffleString(str) {
  const array = str.split("");
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join("");
}

export default GeneratePassword;
