import { format } from "date-fns";
import {
  Body,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Text,
} from "@react-email/components";

export function Email({ firstName }) {
  // const { token, email, firstName } = props;

  return (
    <Html lang="en">
      <Body style={main}>
        <Container style={container}>
          <Img
            src="https://uxzumbtxavruynxjkuxb.supabase.co/storage/v1/object/public/bens_public_assets/logo_red.png"
            width="70"
            height="auto"
            alt="BENS"
            style={logo}
          />
          <Text style={paragraph}>Hi {firstName},</Text>
          <Text style={paragraph}>
            Your password has been changed successfully. If this was you,
            awesome! If not, please let us know right away.
          </Text>

          <Text style={paragraph}>
            If you have any questions or need further assistance, our team is
            here to help.
          </Text>
          <Text style={paragraph}>
            Best regards,
            <br />
            The BENS team
          </Text>
          <Hr style={hr} />
          <Text style={footer}>&copy; {format(new Date(), "yyyy")} BENS</Text>
        </Container>
      </Body>
    </Html>
  );
}

export default Email;

const main = {
  backgroundColor: "#ffffff",
  fontFamily: '"Rubik",sans-serif',
};

const container = {
  margin: "0 auto",
  padding: "20px 0 48px",
};

const logo = {
  margin: "0 auto 32px auto",
};

const paragraph = {
  fontSize: "16px",
  lineHeight: "26px",
};

const btnContainer = {
  textAlign: "center",
};

const button = {
  backgroundColor: "#d70e2b;",
  borderRadius: "4px",
  color: "#fff",
  fontSize: "16px",
  textDecoration: "none",
  textAlign: "center",
  display: "block",
  padding: "12px",
};

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
};
