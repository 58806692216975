import styles from "./Logo.module.css";
import PropTypes from "prop-types";

function Logo({ src, alt = "", className = "" }) {
  return <img src={src} alt={alt} className={`${styles.logo} ${className}`} />;
}

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
