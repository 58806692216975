import { useState } from "react";
import Logo from "../../components/ui/elements/custom/Logo";
import styles from "../auth/Login.module.css";
import { toast } from "react-toastify";
import SendEmail from "../../utils/SendEmail";
import GeneratePassword from "../../utils/GeneratePassword";
import ResetPassword from "../../emails/ResetPassword";
import { render } from "@react-email/components";
import useAuth from "../../auth/useAuth";

function Login() {
  const [forgotPswd, setForgotPswd] = useState(false);
  const [email, setEmail] = useState("");
  const [emailPswdReset, setEmailPswdReset] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");

  const { loginUser } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Email and password are required!");
      return;
    }

    loginUser(email, password);
  };

  const url =
    import.meta.env.MODE === "production"
      ? import.meta.env.VITE_PRD_SERVER_URL
      : import.meta.env.VITE_DEV_SERVER_URL;

  const requestPasswordReset = async (email) => {
    // check if the entered email exists
    try {
      const fetchUserResponse = await fetch(`${url}/fetch-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await fetchUserResponse.json();
      console.log(data);

      if (data.error && data.error.code === "PGRST116") {
        throw new Error(`${email} is not a registered email address!`);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      if (!fetchUserResponse.ok) {
        throw new Error(`HTTP error! Status: ${fetchUserResponse.status}`);
      }

      const { first_name } = data;
      setFirstName(first_name);

      // Send an email to the user
      const resetPswdToken = GeneratePassword(10, {
        lowercase: true,
        uppercase: true,
        numeric: true,
        special: false,
      });

      const updateTokenResponse = await fetch(`${url}/update-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, resetPswdToken }),
      });

      if (!updateTokenResponse.ok) {
        throw new Error(`HTTP error! Status: ${updateTokenResponse.status}`);
      }

      const html = render(
        <ResetPassword
          email={email}
          firstName={firstName}
          token={resetPswdToken}
        />
      );

      SendEmail({ to: email, subject: "Password Reset", html: html });

      return `Password reset instructions have been sent to ${email}!`;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();

    toast.promise(
      requestPasswordReset(emailPswdReset),
      {
        pending: "Resetting your password...",
        success: `Password reset instructions have been sent to ${emailPswdReset}!`,
        error: {
          render({ data }) {
            return (
              <>
                <p>Password reset failed!</p>
                <p className={styles.toast_info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      },
      {
        toastId: "pswd_toast",
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo_and_form}>
        <div className={styles.logo_container}>
          <Logo
            src="/images/BENS-logo-white.png"
            alt="bens logo white"
            className={styles.logo}
          />
        </div>
        <div className={styles.login_form}>
          {forgotPswd ? (
            <>
              <h3 className={styles.page_title}>Reset Password</h3>
              <p className={styles.info}>
                Enter your email and we'll send you instructions on how to reset
                your password.
              </p>
              <form onSubmit={(e) => handlePasswordReset(e)}>
                <label htmlFor="email" className={styles.labels}>
                  Email
                </label>
                <input
                  required
                  type="email"
                  placeholder="johndoe@email.com"
                  id="email"
                  className={styles.textboxes}
                  value={emailPswdReset}
                  onChange={(e) => setEmailPswdReset(e.target.value)}
                ></input>

                <div className={styles.footer}>
                  <a href="#" onClick={() => setForgotPswd(false)}>
                    Back to Sign in
                  </a>
                  <button type="submit">Reset Password</button>
                </div>
              </form>
            </>
          ) : (
            <>
              <h3 className={styles.page_title}>Sign in</h3>
              <form onSubmit={(e) => handleLogin(e)}>
                <label htmlFor="email" className={styles.labels}>
                  Email
                </label>
                <input
                  required
                  type="email"
                  placeholder="johndoe@email.com"
                  id="email"
                  className={styles.textboxes}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <label htmlFor="password" className={styles.labels}>
                  Password
                </label>
                <input
                  required
                  type="password"
                  placeholder="********"
                  id="password"
                  className={styles.textboxes}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className={styles.remember_me}></div>
                <div className={styles.footer}>
                  <a href="#" onClick={() => setForgotPswd(true)}>
                    Forgot password?
                  </a>
                  <button type="submit">Sign in</button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
