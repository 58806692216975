import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./EditUser.module.css";
import { useState, useEffect } from "react";
import { supabase } from "../supabase/client";
import Loader from "../components/ui/elements/custom/Loader";
import ReactSelect from "../components/ui/elements/custom/ReactSelect";
import SendEmail from "../utils/SendEmail";
import AccountUpdated from "../emails/AccountUpdated";
import { render } from "@react-email/components";
import { useUser } from "../auth/userContext";
import isPermitted from "../utils/isPermitted";
import getUserRole from "../utils/getRole";
import { IoChevronForwardSharp } from "react-icons/io5";

function EditUser() {
  const { userProfile } = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [authUserId, setAuthUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userHotels, setUserHotels] = useState([]);
  const [roles, setRoles] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [active, setActive] = useState(false);
  const [originalData, setOriginalData] = useState(null);

  const navigate = useNavigate();
  const params = useParams();
  const { accessToken, authSession } = useUser();

  const fetchUser = async (id) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("users")
        .select(
          `first_name, last_name, full_name, email, role_id, active, auth_user_id, user_hotel(hotels(name, code, id))`
        )
        .eq("id", id)
        .single();

      if (error) {
        toast.error(
          () => (
            <>
              <p>Failed to retrieve user information!</p>
              <p className={styles.info}>Reason: {error.message}</p>
            </>
          ),
          {
            toastId: "errortoast",
          }
        );
        navigate("/users");
        return;
      }

      if (data.auth_user_id === authSession.user.id) {
        toast.error("Wrong operation", {
          toastId: "errortoast",
        });
        navigate("/users");
        return;
      }

      const userHotelsData = data.user_hotel.map((uh) => ({
        value: uh.hotels?.id,
        name: uh.hotels?.name,
        label: `${uh.hotels?.code} | ${uh.hotels?.name}`,
      }));

      setOriginalData({
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        userRole: data.role_id,
        userHotels: userHotelsData,
        active: data.active,
      });

      setAuthUserId(data.auth_user_id);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setFullName(data.full_name);
      setEmail(data.email);
      setUserRole(data.role_id);
      setUserHotels(userHotelsData);
      setActive(data.active);
    } catch (error) {
      toast.error(
        () => (
          <>
            <p>Failed to retrieve user information!</p>
            <p className={styles.info}>Reason: {error.message}</p>
          </>
        ),
        {
          toastId: "errortoast",
        }
      );
      navigate("/users");
    }

    setIsLoading(false);
  };

  const fetchHotels = async () => {
    try {
      const { data, error } = await supabase
        .from("hotels")
        .select("id, name, code")
        .order("code");

      if (error) throw new Error(error.message);

      const hotelList = data.map((hotel) => ({
        value: hotel.id,
        label: `${hotel.code} | ${hotel.name}`,
      }));
      setHotels(hotelList);
    } catch (error) {
      toast.error(
        () => (
          <>
            <p>Failed to retrieve hotel list!</p>
            <p className={styles.info}>Reason: {error.message}</p>
          </>
        ),
        {
          toastId: "errortoast",
        }
      );

      navigate("/users");
    }
  };

  const fetchRoles = async () => {
    try {
      const { data, error } = await supabase
        .from("roles")
        .select("id, role")
        .order("role");

      if (error) {
        throw new Error(error.message);
      }

      setRoles(data);
    } catch (error) {
      toast.error(
        () => (
          <>
            <p>Failed to retrieve roles!</p>
            <p className={styles.info}>Reason: {error.message}</p>
          </>
        ),
        {
          toastId: "errortoast",
        }
      );

      navigate("/users");
    }
  };

  const updateUser = async (e, id) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || !userRole) {
      alert("First name, last name, email, and role are required.");
      return;
    }

    if (
      firstName === originalData.firstName &&
      lastName === originalData.lastName &&
      email === originalData.email &&
      userRole === originalData.userRole &&
      active === originalData.active &&
      JSON.stringify(originalData.userHotels.map((h) => h.name)) ===
        JSON.stringify(userHotels.map((h) => h.name))
    ) {
      toast.info("Nothing was changed!");
      return;
    }

    // Check if user is permitted
    const permitted = await isPermitted(userRole, authSession);
    if (!permitted) {
      toast.error(
        `You are not permitted to update ${await getUserRole(
          userRole
        )} accounts.`
      );

      return;
    }

    const userData = {
      authUserId,
      email,
      firstName,
      lastName,
      userRole: userRole,
      userHotels:
        userHotels.length > 0 ? userHotels.map((hotel) => hotel.value) : null,
      active,
    };

    // Prep message for email
    const updatedFields = [];
    if (originalData.firstName !== firstName) {
      updatedFields.push(
        `First name changed from <strong>${originalData.firstName}</strong> to <strong>${firstName}</strong>.`
      );
    }
    if (originalData.lastName !== lastName) {
      updatedFields.push(
        `Last name changed from <strong>${originalData.lastName}</strong> to <strong>${lastName}</strong>.`
      );
    }
    if (originalData.email !== email) {
      updatedFields.push(
        `Email changed from <strong>${originalData.email}</strong> to <strong>${email}</strong>.`
      );
    }
    if (originalData.userRole !== userRole) {
      const originalRole = roles[originalData.userRole]?.role || "Unknown";
      const newRole = roles[userRole]?.role || "Unknown";
      updatedFields.push(
        `Role changed from <strong>${originalRole}</strong> to <strong>${newRole}</strong>.`
      );
    }
    if (
      JSON.stringify(originalData.userHotels.map((h) => h.name)) !==
      JSON.stringify(userHotels.map((h) => h.name))
    ) {
      updatedFields.push(
        `Hotels changed<br/><p><strong>Previous hotels</strong><br/>${originalData.userHotels
          .map((h) => h.name)
          .join(", ")}</p><p><strong>New hotels</strong><br/>${userHotels
          .map((h) => h.name)
          .join(", ")}</p>`
      );
    }
    if (originalData.active !== active) {
      updatedFields.push(
        `Your account status has been changed from <strong>${
          originalData.active ? "Active" : "Inactive"
        }</strong> to <strong>${active ? "Active" : "Inactive"}</strong>.`
      );
    }

    const url =
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_PRD_SERVER_URL
        : import.meta.env.VITE_DEV_SERVER_URL;

    toast.promise(
      fetch(`${url}/update-user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ userId: id, ...userData }),
      })
        .then((response) => {
          if (!response.ok)
            throw new Error("Failed to update user: ", response.message);
          return response.json();
        })
        .then((data) => data),
      {
        pending: `Updating ${firstName} ${lastName}...`,
        success: {
          render({ data }) {
            navigate("/users");

            const html = render(
              <AccountUpdated
                updatedFields={updatedFields}
                firstName={firstName}
              />
            );

            // Send email notification about the updates
            SendEmail({
              to: email,
              subject: "Your Profile Has Been Updated",
              html: html,
            });

            return `${firstName} ${lastName} successfully updated!`;
          },
        },
        error: {
          render({ data }) {
            return (
              <>
                <p>
                  Updating {firstName} {lastName} failed!
                </p>
                <p className={styles.info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      }
    );
  };

  useEffect(() => {
    fetchHotels();
    fetchRoles();
    if (params.id) {
      fetchUser(params.id);
    }
  }, [params.id, navigate]);

  return (
    <>
      <div className={styles.main_container}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h4 className={styles.page_title}>
              Users <IoChevronForwardSharp /> Edit <IoChevronForwardSharp />{" "}
              {fullName}
            </h4>

            <form
              onSubmit={(e) => {
                updateUser(e, params.id);
              }}
            >
              <div className={styles.form_container}>
                <label htmlFor="firstname" className={styles.labels}>
                  First name
                </label>
                <input
                  required
                  type="text"
                  placeholder="First name"
                  id="firstname"
                  name="firstname"
                  className={styles.textboxes}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <label htmlFor="lastname" className={styles.labels}>
                  Last name
                </label>
                <input
                  required
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastname"
                  name="lastname"
                  className={styles.textboxes}
                />
                <label htmlFor="email" className={styles.labels}>
                  Email
                </label>
                <input
                  required
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  className={styles.textboxes}
                />
                <ReactSelect
                  marginBottom="32px"
                  // options={hotels}

                  options={userProfile.user_hotel
                    .slice() // Clone the array to avoid mutating the original
                    .sort((a, b) => a.hotels.name.localeCompare(b.hotels.name)) // Sort the array by hotel name
                    .map((uh) => ({
                      value: uh.hotels.id,
                      label: uh.hotels.name,
                    }))
                    // <option key={uh.hotels.id} value={uh.hotels.id}>
                    //   {uh.hotels.name}
                    // </option>
                  }
                  value={userHotels}
                  isMulti={true}
                  onChange={(selected) => setUserHotels(selected)}
                  label="Hotels"
                  boldLabel={true}
                  placeholder="Select hotels this user is associated with"
                  noOptionsMsg="No hotels available"
                />
                <label htmlFor="role" className={styles.labels}>
                  Role
                </label>
                <select
                  required
                  className={`${styles.textboxes} ${styles.capitalize}`}
                  name="role"
                  id="role"
                  value={userRole}
                  onChange={(e) => setUserRole(e.target.value)}
                >
                  <option value="">--</option>
                  {roles.map(
                    (role) =>
                      role.role != authSession.user.app_metadata.role && (
                        <option value={role.id} key={role.id}>
                          {role.role}
                        </option>
                      )
                  )}
                </select>
                <div className={styles.active}>
                  <input
                    type="checkbox"
                    checked={active}
                    onChange={() => setActive(!active)}
                    id="active"
                    name="active"
                    className={styles.checkbox}
                  />
                  <label
                    htmlFor="active"
                    className={`${styles.labels} ${styles.checkbox_label}`}
                  >
                    Active
                  </label>
                </div>
                <div className={styles.fn_btns}>
                  <button
                    onClick={() => navigate("/users")}
                    title="Return to user list"
                    className="neutral_btn"
                  >
                    Cancel
                  </button>
                  <button type="submit" title="Update this user">
                    Update user
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default EditUser;
